import { SectionFeaturedBlogPostsProps } from '@portfolio/models'
import { styled } from '@portfolio/styles'
import { Link, Media, SectionWrapper } from '@portfolio/components'

const StyledSectionWrapper = styled(SectionWrapper, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingInline: 20,

  '@xl': {
    paddingInline: 30,
  },
})
const Heading = styled('h2', {
  projectFont: 'heading02',
  color: '$white',
  textAlign: 'center',
  marginBottom: '$96',

  '@xl': {
    marginBottom: '$120',
  },
})
const CardLink = styled(Link, {
  pointerEvents: 'all',
  zIndex: '$cardButton',
  width: 'max-content',
})
const CardsWrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: 30,
  marginBottom: '$40',

  '@xl': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },

  '@media (min-width: 1522px)': {
    gridTemplateColumns: 'unset',
    gridAutoFlow: 'column',
    gridAutoColumns: 486,
  },
})
const Card = styled('div', {
  background: '$techstackCardsBackground',
  borderRadius: '$r3',
  overflow: 'hidden',
  height: '100%',
  maxWidth: 440,

  '@media (min-width: 1522px)': {
    maxWidth: 'unset',
  },
})
const ArticleNameWrap = styled('div', {
  padding: 20,

  '@xl': {
    padding: 30,
  },
})
const ArticleTitle = styled('h3', {
  projectFont: 'heading08',
  color: '$white',
})
const ImageWrap = styled('div', {
  position: 'relative',
  height: 220,
  overflow: 'hidden',
})
// TODO: Uncomment when Tags are added to articles
// const Tag = styled('div', {
//   textTransform: 'uppercase',
//   projectFont: 'ui06',
//   color: '$white',
//   background: '$black_02',
//   width: 'max-content',
//   padding: '8px 12px',
//   marginTop: '$16',
//   borderRadius: '$rMax',
// })

export const SectionFeaturedBlogPosts = ({
  title,
  featuredArticles,
  priority,
  ctaLabel,
  ctaLink,
}: SectionFeaturedBlogPostsProps) => {
  return (
    <StyledSectionWrapper type="DEFAULT">
      {title && <Heading>{title}</Heading>}
      <CardsWrap>
        {featuredArticles?.map(({ link, image, title, id }, idx) => (
          <Link href={link} key={`${id}-${idx}`}>
            <Card>
              <ImageWrap>
                {image && (
                  <Media
                    {...image}
                    priority={priority}
                    sizes="440px"
                    objectFit="cover"
                    layout="fill"
                  />
                )}
              </ImageWrap>
              <ArticleNameWrap>
                {title && <ArticleTitle>{title}</ArticleTitle>}
                {/*<Tag>Shopify</Tag>*/}
              </ArticleNameWrap>
            </Card>
          </Link>
        ))}
      </CardsWrap>
      {ctaLink && ctaLabel && (
        <CardLink appearance="containedTransparent" href={ctaLink.href}>
          {ctaLabel}
        </CardLink>
      )}
    </StyledSectionWrapper>
  )
}
